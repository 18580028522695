<template>
  <div>
    <SearchBar
      :isSearching="isSearching"
      :searchTerm="tagsPageState.searchTerm"
      :placeHolderText="'Search tags'"
      @startSearch="searchTags"
    />

    <hr />

    <section class="section">
      <div class="container">
        <table class="table is-fullwidth is-striped">
          <tbody>
            <tr v-for="tag in tagsPageState.tags" :key="tag.Id">
              <td>
                <a @click="showTagSummary(tag)"
                  ><span class="tag is-link">{{ tag.Text }}</span></a
                >
              </td>
              <td>{{ tag.Count }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import tagProvider from '@/providers/tag'

const SearchBar = () => import('@/components/SearchBar')

export default {
  components: {
    SearchBar,
  },

  props: {},

  data() {
    return {
      itemId: 0,
      isSearching: false,
    }
  },

  computed: {
    ...mapState('tagStore', ['tagsPageState']),
  },

  created() {},

  methods: {
    ...mapMutations('tagStore', ['setTagsPageState']),

    searchTags(searchTerm = '') {
      let self = this
      self.isSearching = false

      self.tagsPageState.searchTerm = searchTerm

      self.getTags()
    },

    getTags() {
      let self = this
      self.isSearching = true

      tagProvider.methods
        .getTags(
          self.tagsPageState.searchTerm,
          self.tagsPageState.page,
          self.tagsPageState.itemsPerPage
        )
        .then((response) => {
          if (response.status === 200) {
            self.tagsPageState.tags = response.data
          }
        })
        .finally(() => {
          self.isSearching = false
        })

      self.setTagsPageState(self.tagsPageState)
    },

    showTagSummary(tag) {
      this.$emit('showTagSummary', tag)
    },
  },
}
</script>

<style></style>
